import { render, staticRenderFns } from "./PluginConfirmDialog.vue?vue&type=template&id=3173a21e&scoped=true&"
import script from "./PluginConfirmDialog.vue?vue&type=script&lang=js&"
export * from "./PluginConfirmDialog.vue?vue&type=script&lang=js&"
import style0 from "./PluginConfirmDialog.vue?vue&type=style&index=0&id=3173a21e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3173a21e",
  null
  
)

export default component.exports